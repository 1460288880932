import React from 'react';

export default ({ style, className }) => (
  <svg
    className={className}
    style={{ fill: '#ffbd00', ...style }}
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 212.246 80.452"
    enableBackground="new 0 0 212.246 80.452"
    xmlSpace="preserve"
  >
    <g id="Pmtcxj.tif">
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0,37.664c1.88-8.188,3.585-16.397,3.568-25.332
			c4.598-1.924,9.659-2.875,14.835-0.049c3.422,1.868,4.623,4.94,3.381,8.622c-1.759,5.219-4.644,9.751-9.797,11.933
			c-3.619,1.532-4.679,4.099-5.149,7.468c-0.224,1.6-0.64,3.174-1.077,5.282C3.635,44.933,1.818,44.373,0,43.812
			C0,41.762,0,39.713,0,37.664z M8.747,27.271c0.346,0.26,0.693,0.521,1.04,0.783c2.52-3.539,5.04-7.078,7.523-10.566
			C12.644,13.781,10.335,16.805,8.747,27.271z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M125.288,0c1.429,1.803,5.843,0.504,5.026,4.726
			c-6.592,1.172-6.463,1.196-7.611,7.636c-1.305,7.324-2.871,14.602-4.312,21.844c-4.943-0.371-6.14-1.688-5.535-5.815
			c1.052-7.16,2.166-14.312,3.252-21.451c-2.127-0.53-4.05-0.847-5.811-1.561c-0.467-0.189-0.438-1.601-0.639-2.466
			c4.305-0.999,8.43-1.956,12.555-2.913C123.237,0,124.264,0,125.288,0z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M80.82,39.151c-8.261-2.012-5.259-10.358-9.259-14.728
			c-0.581,3.882-1.122,7.743-1.752,11.587c-0.264,1.613,0.216,4.102-2.591,3.66c-2.636-0.414-4.52-2.018-4.06-5.033
			c1.039-6.804,2.203-13.588,3.329-20.378c0.473-2.853,0.98-5.699,1.468-8.528c6.045,0.822,6.785,1.599,7.287,6.903
			c0.18,1.894,0.545,3.777,0.927,5.645c0.174,0.853,0.59,1.655,1.195,3.286c1.018-6.064,1.894-11.284,2.768-16.497
			c4.697-0.625,7.172,1.364,6.482,5.558c-1.463,8.885-3.137,17.735-4.767,26.592C81.737,37.807,81.269,38.329,80.82,39.151z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M60.475,40.349c-5.718,0.584-6.439,0.159-7.856-5.023
			c-2.889,2.155-5.426,4.357-8.284,6.001c-0.923,0.53-3.583-0.02-3.891-0.74c-0.726-1.702-1.182-4.05-0.571-5.7
			c3.132-8.455,6.555-16.806,10.108-25.094c0.472-1.101,2.275-2.3,3.444-2.281c1.987,0.031,4.126,0.285,4.311,3.531
			c0.438,7.714,1.373,15.4,2.112,23.098C60.04,36.129,60.25,38.117,60.475,40.349z M47.436,32.208
			c5.717-1.734,5.866-2.093,4.083-9.583C50.073,26.019,48.866,28.852,47.436,32.208z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M168.562,37.284c3.753-0.208,7.806-0.9,11.763-0.541
			c5.448,0.497,9.439,6.799,8.148,12.133c-2.217,9.156-7.636,16.06-15.62,20.801c-1.438,0.854-4.19,0.477-5.822-0.349
			c-0.996-0.504-1.562-3.026-1.364-4.515c0.939-7.062,2.299-14.069,3.298-21.126C169.228,41.817,168.746,39.84,168.562,37.284z
			 M172.534,61.668c5.148-3.877,9.565-8.157,10.392-14.658c0.654-5.14-1.265-6.665-6.439-5.497
			C175.17,48.224,173.853,54.946,172.534,61.668z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M141.542,51.863c-0.642,7.279-1.987,13.714-6.498,19.019
			c-2.184,2.568-4.857,4.136-8.403,3.29c-3.698-0.885-6.145-3.104-6.768-6.927c-1.398-8.591,0.586-16.451,5.595-23.498
			c2.15-3.025,5.369-3.763,8.897-3.014c3.308,0.704,5.369,2.869,6.257,6.054C141.13,48.611,141.31,50.527,141.542,51.863z
			 M134.216,45.157c-1.374,0.309-1.717,0.277-1.849,0.43c-5.572,6.368-6.747,13.988-6.15,22.067
			c0.044,0.602,1.018,1.698,1.272,1.621c0.873-0.265,2.083-0.785,2.338-1.502c1.725-4.857,3.514-9.726,4.694-14.727
			C135.087,50.655,134.366,47.96,134.216,45.157z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M59.061,48.637c4.055-1.836,8.502-2.724,13.047-1.03
			c5.331,1.987,7.076,6.515,4.097,11.312c-1.983,3.191-4.738,5.905-7.418,9.162c2.103,3.186,4.454,6.747,6.863,10.396
			c-3.078,2.587-5.156,1.072-7.079-1.041c-1.769-1.943-3.468-3.952-5.599-6.39c-0.706,3.539-1.293,6.486-1.875,9.405
			c-4.712-0.316-5.806-1.594-5.14-5.779c1.04-6.535,2.138-13.061,3.062-19.611C59.292,53.125,59.061,51.118,59.061,48.637z
			 M73.033,53.434c-3.06-1.691-5.674-2.858-6.787-0.239c-1.338,3.148-1.455,6.815-2.1,10.258c0.426,0.176,0.853,0.352,1.279,0.526
			C67.867,60.596,70.308,57.211,73.033,53.434z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M163.524,70.443c-7.652,2.511-8.671-4.891-13.042-7.463
			c-0.611,3.07-1.197,6.013-1.778,8.931c-4.582-0.173-5.784-1.543-5.126-5.681c1.043-6.549,2.145-13.092,3.07-19.658
			c0.273-1.935,0.042-3.939,0.042-6.343c3.879-1.97,8.45-2.78,13.12-1.043c5.218,1.941,6.783,6.615,3.984,11.42
			c-0.953,1.637-2.16,3.154-3.422,4.574c-1.256,1.411-2.728,2.631-4.232,4.057C158.49,62.803,160.789,66.291,163.524,70.443z
			 M151.816,54.917c0.408,0.215,0.814,0.43,1.222,0.645c2.469-3.454,4.937-6.909,7.469-10.455c-5.317-2.973-6.894-2.281-7.545,2.367
			C152.614,49.958,152.201,52.436,151.816,54.917z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M209.768,50.312c-5.247,0.433-6.229-1.8-4.781-11.139
			c-0.303-0.132-0.604-0.263-0.905-0.396c-1.639,3.965-3.356,7.9-4.822,11.926c-0.207,0.565,0.666,1.737,1.312,2.333
			c0.501,0.46,1.418,0.507,2.167,0.653c5.44,1.06,7.311,2.676,6.886,5.989c-0.616,4.818-5.298,8.881-9.806,8.51
			c-5.931-0.487-9.273-3.453-9.005-8.212c3.958,0.831,7.428,4.667,12.209,1.381c-1.383-0.61-2.726-1.338-4.155-1.81
			c-6.072-2.004-8.316-5.764-6.134-11.749c1.373-3.763,3.513-7.319,5.769-10.656c0.958-1.417,2.996-2.61,4.717-2.911
			c6.415-1.12,10.984-0.351,8.189,8.29C210.601,45.023,210.306,47.689,209.768,50.312z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M108.09,7.278c-9.236,0.729-11.002,2.3-11.756,10.091
			c2.515,0.229,5.043,0.36,7.532,0.748c0.707,0.11,1.298,0.966,1.942,1.48c-0.555,0.573-1.01,1.384-1.685,1.665
			c-1.273,0.529-2.676,0.742-4.02,1.102c-5.465,1.462-6.5,2.96-6.295,9.601c1.468-0.875,2.74-1.636,4.015-2.389
			c0.878-0.519,1.817-1.525,2.635-1.436c1.778,0.191,3.496,0.942,5.238,1.465c-0.819,1.275-1.348,3.112-2.515,3.713
			c-2.896,1.494-6,2.811-9.173,3.489c-4.813,1.029-7.18-1.991-6.603-6.962c0.733-6.314,2.308-12.526,3.342-18.812
			c0.317-1.923,0.05-3.942,0.05-6.458c4.581,0.02,9.444-3.482,15.181-1.222C108.281,4.26,108.535,5.254,108.09,7.278z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M113.605,63.321c3.016,2.184,3.198,5.296,2.205,8.288
			c-1.058,3.187-3.929,4.673-7.141,5.021c-3.229,0.351-6.311-0.562-7.91-3.548c-1.164-2.173-2.02-4.761-2.128-7.204
			c-0.305-6.906,1.449-13.399,5.375-19.167c2.369-3.48,6.11-4.742,10.096-3.607c2.535,0.724,3.849,2.406,3.65,5.096
			c-0.262,3.55-0.573,7.096-0.865,10.665c-5.05-0.959-5.895-2.854-4.94-10.617c-5.534,3.932-8.56,17.405-4.866,24.829
			C109.417,69.583,111.46,66.531,113.605,63.321z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M86.557,58.479c3.432,0.688,6.331,1.27,9.23,1.853
			c0.032,0.405,0.064,0.811,0.096,1.217c-1.292,0.483-2.554,1.08-3.88,1.432c-7.492,1.993-7.497,1.975-7.504,10.127
			c3.869-0.463,7.051-6.881,12.273-0.705c-3.795,2.049-7.229,4.394-11.012,5.805c-3.562,1.33-8.747-2.905-7.654-7.384
			c1.444-5.919,2.397-11.965,3.361-17.988c0.328-2.046,0.052-4.188,0.052-6.865c5.36,0.667,11.785-5.616,17.841,1.74
			c-3.568,0.835-7.422,0.709-9.618,2.556C87.73,51.958,87.506,55.779,86.557,58.479z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.524,9.622c6.568,0.708,7.612,2.102,6.421,7.696
			c-1.416,6.65-2.745,13.318-4.076,19.804c2.3-0.43,4.613-1.127,6.937-1.169c0.918-0.017,2.139,1.28,2.657,2.273
			c0.198,0.377-1.059,1.757-1.883,2.369c-1.211,0.899-2.592,1.596-3.965,2.241c-5.198,2.442-10.582-0.779-9.998-6.501
			C22.532,27.382,24.187,18.504,25.524,9.622z"
        />
      </g>
    </g>
  </svg>
);
